import React from 'react'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import { StaticImage } from 'gatsby-plugin-image'
import widgetBouton from '../../assets/images/widgetBouton.gif'
import widgetImagephotoshop from '../../assets/images/widgetImagephotoshop.gif'
const ShowCase = () => {
  const { t } = useTranslation()
  return (
    <section className="container-blue showcase-background">
      <div className="showcase container ">
        <h2 className=" h3-style secondary showcase__title">
          {t('page_home_section_showcase_title')}
        </h2>
        <div className="showcase__images">
          <StaticImage
            src="../../assets/images/showcase.png"
            alt={t('page_home_section_showcase_title')}
          />
          <div className="image-overflow-right">
            <img
              src={widgetImagephotoshop}
              alt={t('page_home_section_showcase_title')}
            />
          </div>
          <div className="image-overflow-left">
            <img
              src={widgetBouton}
              alt={t('page_home_section_showcase_title')}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ShowCase
